import React, { useEffect } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Button from '@cotyorg/ccx-atoms/Button';
import { pagesNames, shadeFinderSteps } from '../helpers/constants';
import BestResultsListItem from './molecules/BestResultsListItem';
import { pageViewTracking } from '../tracking';

const BestResultsGuide = ({
  shadeFinderConfiguration: {
    instructions,
    bestResultsHeading,
    bestResultsContinueButtonLabel,
  },
  setShadeFinderStep,
  vtoType,
}) => {
  useEffect(
    () => pageViewTracking({ pageName: pagesNames.FOR_BEST_RESULT, vtoType }),
    []
  );

  return (
    <div className="shade-finder__best-results">
      <h2 className="shade-finder__best-results-heading">
        {bestResultsHeading}
      </h2>

      <div className="shade-finder__best-results-list">
        {R.map(
          (item) => (
            <BestResultsListItem
              key={R.prop('instruction_text', item)}
              text={R.prop('instruction_text', item)}
              imageSrc={R.path(['instruction_image', 'url'], item)}
            />
          ),
          // TODO: instructions contains useless CMS data.
          // It should be build and cleaned before sending to FE
          R.prop('instruction', instructions)
        )}
      </div>

      <Button
        theme="primary"
        onClick={() => setShadeFinderStep(shadeFinderSteps.VTO)}
      >
        {bestResultsContinueButtonLabel}
      </Button>
    </div>
  );
};

BestResultsGuide.propTypes = {
  shadeFinderConfiguration: PropTypes.shape({
    triggerButtonLabel: PropTypes.string,
    privacyPolicyContent: PropTypes.string,
    privacyPolicyLink: PropTypes.string,
    privacyPolicyLinkLabel: PropTypes.string,
    acceptPolicyButtonLabel: PropTypes.string,
    rejectPolicyButtonLabel: PropTypes.string,
    instructions: PropTypes.shape({
      instruction: PropTypes.array.isRequired,
    }).isRequired,
    bestResultsHeading: PropTypes.string.isRequired,
    bestResultsContinueButtonLabel: PropTypes.string.isRequired,
  }),
  setShadeFinderStep: PropTypes.func.isRequired,
  vtoType: PropTypes.string.isRequired,
};

export default BestResultsGuide;
