import React, { useEffect, useState } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Question from '../molecules/Question';
import { shadeFinderSteps } from '../../ShadeFinder/helpers/constants';
import { findProductRecommendation } from '../helpers/quiz';

const ShadeFinderQuiz = ({
  setShadeFinderStep,
  shadeFinderQuizConfiguration,
  quizAnswers,
  setQuizAnswers,
  quizResultsMapping,
  setProductRecommendation,
  vtoType,
}) => {
  const [quizStep, setQuizStep] = useState(0);
  const quizData = R.prop('questions', shadeFinderQuizConfiguration);
  const quizLength = quizData.length;

  useEffect(() => {
    if (quizStep === quizLength) {
      setShadeFinderStep(shadeFinderSteps.GUIDE);

      const productRecommendation = findProductRecommendation({
        quizAnswers,
        quizResultsMapping,
      });

      setProductRecommendation(productRecommendation);
    }
  }, [quizStep]);

  const backButtonLabel = R.pathOr(
    '',
    ['labels', 'back_button_label'],
    shadeFinderQuizConfiguration
  );

  return (
    <div className="shade-finder-quiz">
      <div className="shade-finder-quiz__header">
        <img
          src="/ccx-files/assets/logo.svg"
          alt="Rimmel logo"
          className="shade-finder-quiz__header-logo"
        />
      </div>

      <div className="shade-finder-quiz__content">
        {quizLength && quizStep < quizLength && (
          <>
            <div className="shade-finder-quiz__questions-wrapper">
              <Question
                data={quizData[quizStep]}
                quizStep={quizStep}
                setQuizStep={setQuizStep}
                quizLength={quizLength}
                backButtonLabel={backButtonLabel}
                quizAnswers={quizAnswers}
                setQuizAnswers={setQuizAnswers}
                vtoType={vtoType}
              />
            </div>

            <span className="shade-finder-quiz__step">
              {quizStep + 1}/{quizLength}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

ShadeFinderQuiz.propTypes = {
  setShadeFinderStep: PropTypes.func.isRequired,
  shadeFinderQuizConfiguration: PropTypes.object.isRequired,
  quizAnswers: PropTypes.array,
  setQuizAnswers: PropTypes.func.isRequired,
  quizResultsMapping: PropTypes.object,
  setProductRecommendation: PropTypes.func.isRequired,
  vtoType: PropTypes.string,
};

export default ShadeFinderQuiz;
