import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import ProductSwatchColoursItemMarkup from '@cotyorg/ccx-organism-product-swatch/components/ProductSwatchColours/ProductSwatchColoursItemMarkup';

export const Swatches = forwardRef(
  (
    {
      swatches,
      vtoActualProductSku,
      extraClass = '',
      enableRemoveSelectionSwatch,
      onChange,
    },
    ref
  ) => (
    <ProductSwatchColoursItemMarkup
      ref={ref}
      enableQuantityCheck={enableRemoveSelectionSwatch}
      uniqueId={`vto-modal${extraClass}`}
      swatches={swatches}
      selectedSKU={vtoActualProductSku}
      setProductSKU={() => {}}
      onChange={onChange}
    />
  )
);

Swatches.propTypes = {
  swatches: PropTypes.array,
  vtoActualProductSku: PropTypes.string,
  extraClass: PropTypes.string,
  enableRemoveSelectionSwatch: PropTypes.bool,
  onChange: PropTypes.func,
};
