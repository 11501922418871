import R from 'ramda';
import classNames from 'classnames';
import { vtoTypes } from './config';

export const trackingVtoTypes = {
  [vtoTypes.MAKEUP_FINDER]: 'makeup',
  [vtoTypes.SHADE_FINDER]: 'shade finder',
  [vtoTypes.NAIL_VTO]: 'nail',
};

export const getVtoType = ({
  actualProductSlug,
  shadeFinderProducts,
  makeupFinderProducts,
}) => {
  if (R.find(R.propEq('slug', actualProductSlug), shadeFinderProducts)) {
    return vtoTypes.SHADE_FINDER;
  }

  if (R.find(R.propEq('slug', actualProductSlug), makeupFinderProducts)) {
    return vtoTypes.MAKEUP_FINDER;
  }

  return vtoTypes.NAIL_VTO;
};

export const getSelectedProduct = ({ products, actualProductSlug }) =>
  R.find(R.propEq('slug', actualProductSlug), products);

const mapVariantsToSwatch = ({ variants, bestMatchSku, enableClearSwatch }) => {
  const emptyShade = {
    uid: 'empty-shade',
    sku: '',
    itemName: '',
    skuColor: `#fff`,
    skuImage: '',
    availableQuantity: 0,
  };

  const shades = Array.isArray(variants)
    ? R.map(
        ({ uid, sku, name, colour, ingredients, texture }) => ({
          uid,
          sku,
          itemName: name,
          ...(colour && { skuColor: `#${colour}` }),
          ingredients,
          skuImage: texture,
          customName: bestMatchSku === sku ? 'best match' : '',
          availableQuantity: 1,
        }),
        variants
      )
    : [];

  return enableClearSwatch ? [emptyShade, ...shades] : shades;
};

export const getProductSwatches = ({
  product,
  bestMatchSku,
  enableClearSwatch,
}) =>
  mapVariantsToSwatch({
    variants: R.prop('variants')(product),
    bestMatchSku,
    enableClearSwatch,
  });

export const buildResultLink = ({ selectedProduct, selectedProductSwatch }) =>
  `${R.prop('productUrl', selectedProduct)}?shade=${encodeURIComponent(
    R.prop('itemName', selectedProductSwatch)
  )}#buy-now`;

export const getSelectedSwatch = ({ vtoActualProductSku, swatches }) =>
  R.find(R.propEq('sku', vtoActualProductSku), swatches);

export const getMultiColorShadeNames = ({ shades }) =>
  shades.map((shade) => shade.name).join(', ');

export const generateFacebookSharingScriptContent = ({
  facebookSharingId,
  autoLogAppEvents = true,
  xfbml = true,
  version,
}) => `window.fbAsyncInit = function() {
    FB.init({
      appId            : '${facebookSharingId}',
      autoLogAppEvents : ${autoLogAppEvents},
      xfbml            : ${xfbml},
      version          : '${version}'
    });
  }`;

export const selectVtoLaunchMethod = ({ vtoType }) => {
  switch (vtoType) {
    case vtoTypes.NAIL_VTO:
      return YMK.openHandAR;
    case vtoTypes.SHADE_FINDER:
      return YMK.openShadeFinder;
    case vtoTypes.MAKEUP_FINDER:
      return YMK.open;

    default:
      return YMK.openShadeFinder;
  }
};

export const generateVtoControlsClassNames = ({ isVtoLoaded, extraClass }) =>
  classNames('vto__controls-container', extraClass, {
    'vto__controls-container--disabled': !isVtoLoaded,
  });

const getMatchedProducts = ({ vtoProducts, matchedSkus }) =>
  R.filter(
    (data) => R.prop('product', data),
    R.map(
      (sku) => ({
        sku,
        product: R.find((product) =>
          R.find(R.propEq('sku', sku), R.propOr([], 'variants', product))
        )(vtoProducts),
      }),
      matchedSkus
    )
  );

const mapShadesToSwatch = ({ variants }) =>
  Array.isArray(variants)
    ? R.map(
        ({ uid, sku, name, colour, ingredients, texture, customName, id }) => ({
          id,
          uid,
          sku,
          itemName: name,
          ...(colour && { skuColor: `#${colour}` }),
          ingredients,
          skuImage: texture,
          customName,
        }),
        variants
      )
    : [];

const getBestMatchProduct = ({ bestMatchProducts, recommendedProduct }) =>
  R.find(
    (product) =>
      !!R.find(
        R.propEq('sku', R.prop('sku', product)),
        R.propOr([], 'variants', recommendedProduct)
      ),
    bestMatchProducts
  ) || R.prop(0, bestMatchProducts);

export const getBestMatch = ({
  bestMatchData,
  vtoProducts,
  productRecommendation,
}) => {
  const matchedSkus = R.map(R.path(['matched', 'skuId']), bestMatchData);
  const recommendedProduct = R.find(
    R.propEq('uid', productRecommendation),
    vtoProducts
  );

  // TODO: check if recommended product can be matched to any of the matchedSkus -
  // if so, use it as bestMatchProductData, otherwise get bestMatchProductData via getMatchedProducts as fallback
  const bestMatchProducts = getMatchedProducts({
    vtoProducts,
    matchedSkus,
  });
  const bestMatchProductData = getBestMatchProduct({
    bestMatchProducts,
    recommendedProduct,
  });

  const bestMatchSku = R.prop('sku', bestMatchProductData);
  const bestMatchProduct = R.prop('product', bestMatchProductData);
  const bestMatchIndex = R.findIndex(
    R.pathEq(['matched', 'skuId'], bestMatchSku),
    bestMatchData
  );

  if (bestMatchIndex > -1 && bestMatchProduct) {
    const bestMatch = R.filter(
      (item) => !!R.prop('sku', item),
      [
        {
          ...R.find(
            (variant) =>
              R.propEq(
                'sku',
                R.path([bestMatchIndex, 'lighter', 'skuId'], bestMatchData),
                variant
              ),
            R.prop('variants', bestMatchProduct)
          ),
          customName: 'lighter',
        },
        {
          ...R.find(
            (variant) =>
              R.propEq(
                'sku',
                R.path([bestMatchIndex, 'cooler', 'skuId'], bestMatchData),
                variant
              ),
            R.prop('variants', bestMatchProduct)
          ),
          customName: 'cooler',
        },
        {
          ...R.find(
            (variant) =>
              R.propEq(
                'sku',
                R.path([bestMatchIndex, 'matched', 'skuId'], bestMatchData),
                variant
              ),
            R.prop('variants', bestMatchProduct)
          ),
          id: 'matched',
          customName: 'best match',
        },
        {
          ...R.find(
            (variant) =>
              R.propEq(
                'sku',
                R.path([bestMatchIndex, 'warmer', 'skuId'], bestMatchData),
                variant
              ),
            R.prop('variants', bestMatchProduct)
          ),
          customName: 'warmer',
        },
        {
          ...R.find(
            (variant) =>
              R.propEq(
                'sku',
                R.path([bestMatchIndex, 'darker', 'skuId'], bestMatchData),
                variant
              ),
            R.prop('variants', bestMatchProduct)
          ),
          customName: 'darker',
        },
      ]
    );
    const bestMatchSwatches = mapShadesToSwatch({
      variants: bestMatch,
    });

    return {
      bestMatchSku,
      bestMatchProduct,
      bestMatchSwatches,
    };
  }
  return {};
};

export const getVtoSourceUrl = ({ vtoType, vto }) => {
  switch (vtoType) {
    case vtoTypes.SHADE_FINDER:
      return R.propOr('', 'vtoSourceUrl', vto);
    case vtoTypes.MAKEUP_FINDER:
      return R.propOr('', 'makeupVtoSourceUrl', vto);
    case vtoTypes.NAIL_VTO:
      return R.propOr('', 'nailVtoSourceUrl', vto);

    default:
      return R.propOr('', 'vtoSourceUrl', vto);
  }
};

export const base64ToBlob = (base64String, contentType = 'image/png') => {
  const byteCharacters = atob(
    base64String.replace(/^data:image\/\w+;base64,/, '')
  );
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};
