import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@cotyorg/ccx-atoms/Icon';

const BestResultsListItem = ({ text, imageSrc }) => (
  <div className="shade-finder__best-results-list-item">
    <Icon
      iconSrc={imageSrc}
      iconExtraClass="shade-finder__best-results-list-item-image"
      alt={text}
    />

    <p className="shade-finder__best-results-list-item-text">{text}</p>
  </div>
);

BestResultsListItem.propTypes = {
  text: PropTypes.string,
  imageSrc: PropTypes.string,
};

export default BestResultsListItem;
