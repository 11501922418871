import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import Button from '@cotyorg/ccx-atoms/Button';
import Link from '@cotyorg/ccx-atoms/Link';
import Picture from '@cotyorg/ccx-atoms/Picture';

const BasketModalComponent = ({
  modalTitle,
  items,
  modalContinueMessage,
  modalGoToBasketMessage,
  hideModal,
  basketUrl,
  isModalShown,
  quantityLabel,
  priceLabel,
}) => {
  if (!isModalShown) {
    return null;
  }

  return (
    <div className="basket-modal">
      <div className="basket-modal__inner">
        <h1 className="basket-modal__title">{modalTitle}</h1>
        <div className="basket-modal__content">
          {Array.isArray(items) &&
            items.map((item) => (
              <div
                className="basket-modal__item"
                key={R.prop('productId', item)}
              >
                <Picture
                  alt={R.prop('name', item)}
                  src={R.prop('image', item)}
                  extraClass="basket-modal__item__image"
                />
                <div className="basket-modal__item-content">
                  <h2 className="basket-modal__item__name">
                    {R.prop('name', item)}
                  </h2>
                  <p className="basket-modal__item__shade">
                    {R.prop('shadeName', item)}
                  </p>
                  <div className="basket-modal__qty-price">
                    <div className="basket-modal__item__quantity">
                      {quantityLabel}{' '}
                      <span className="basket-modal__item__value">
                        {R.prop('quantity', item)}
                      </span>
                    </div>
                    <div className="basket-modal__item__price">
                      {priceLabel}{' '}
                      <span className="basket-modal__item__value">
                        {R.prop('currency', item)}
                        {R.prop('price', item)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="basket-modal__buttons flex-container">
          <Button theme="secondary" onClick={hideModal}>
            {modalContinueMessage}
          </Button>
          <Link
            extraClass="button button--large button--primary"
            link={basketUrl}
          >
            {modalGoToBasketMessage}
          </Link>
        </div>
      </div>
    </div>
  );
};

BasketModalComponent.propTypes = {
  basketUrl: PropTypes.string,
  isModalShown: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  modalTitle: PropTypes.string,
  modalContinueMessage: PropTypes.string,
  modalGoToBasketMessage: PropTypes.string,
  quantityLabel: PropTypes.string,
  priceLabel: PropTypes.string,
  hideModal: PropTypes.func,
};

export default BasketModalComponent;
