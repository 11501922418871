import React, { useEffect, useRef } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import Picture from '@cotyorg/ccx-atoms/Picture';
import classNames from 'classnames';

const generateItemClassNames = ({ isSelected }) =>
  classNames('mini-product-cardo-list__cardo', {
    'mini-product-cardo-list__cardo--selected': isSelected,
  });

export const MiniProductCardoListItem = ({
  miniProductCardo,
  onSelect,
  selectedProduct,
}) => {
  const isSelected = R.equals(
    R.prop('slug', selectedProduct),
    R.prop('slug', miniProductCardo)
  );
  const selectedCardoRef = useRef();

  useEffect(() => {
    if (selectedCardoRef.current) {
      selectedCardoRef.current.scrollIntoView({ inline: 'center' });
    }
  }, []);

  return (
    <div
      ref={isSelected ? selectedCardoRef : null}
      tabIndex={0}
      role="button"
      className={generateItemClassNames({ isSelected })}
      onClick={() => {
        if (onSelect) {
          onSelect(miniProductCardo);
        }
      }}
      onKeyDown={(e) => {
        if (onSelect && e.key === 'Enter') {
          onSelect(miniProductCardo);
        }
      }}
    >
      <div className="mini-product-cardo-list__cardo-wrapper">
        <Picture
          alt={R.path(['image', 'alt'], miniProductCardo)}
          src={R.path(['image', 'imageSrc'], miniProductCardo)}
          srcSet={R.path(['image', 'imageSrcSet'], miniProductCardo)}
          extraClass="mini-product-cardo-list__image"
          wrapperExtraClass="mini-product-cardo-list__image"
        />

        <div className="mini-product-cardo-list__description">
          <p className="mini-product-cardo-list__heading">
            {R.prop('heading', miniProductCardo)}
          </p>
        </div>
      </div>
    </div>
  );
};

MiniProductCardoListItem.propTypes = {
  miniProductCardo: PropTypes.object,
  onSelect: PropTypes.func,
  selectedProduct: PropTypes.object,
};
