import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const generateTriggerButtonClass = ({ extraclass }) =>
  classNames('vto__button', extraclass);

const VtoButton = ({ extraclass, onClick, text, iconSrc, setRef }) => (
  <button
    ref={(ref) => {
      if (setRef) {
        setRef(ref);
      }
    }}
    type="button"
    className={generateTriggerButtonClass({ extraclass })}
    onClick={onClick}
  >
    <img alt="vto button" className="icon" src={iconSrc} />

    {text}
  </button>
);

VtoButton.propTypes = {
  extraclass: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  iconSrc: PropTypes.string,
  setRef: PropTypes.func,
};

export default VtoButton;
