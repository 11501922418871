import React, { useEffect, useState } from 'react';

export const Loader = () => {
  const [imageSrcToggler, setImageSrcToggler] = useState(true);

  useEffect(() => {
    const loaderInterval = setInterval(() => {
      setImageSrcToggler((toggler) => !toggler);
    }, 500);

    return () => clearInterval(loaderInterval);
  }, []);

  return (
    <div className="loader-wrapper">
      <img
        alt="loader"
        src={
          imageSrcToggler
            ? '/ccx-files/assets/MG_icon.png'
            : '/ccx-files/assets/MG_Icon_Full.png'
        }
        className="loader-image"
      />
    </div>
  );
};
