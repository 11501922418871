export const homeCardoBreakpointConfig = {
  xsmall: {
    contentOverlay: {
      coverImage: true,
      verticalOffsetType: 'inside',
      horizontalOffset: false,
    },
    contentAlignment: {
      horizontal: 'middle',
      vertical: 'bottom',
    },
    gridSetup: {
      image: {
        columns: 16,
      },
      content: {
        columns: 16,
      },
      card: {
        columns: 16,
      },
    },
  },
  medium: {
    gridSetup: {
      card: {
        columns: 'auto',
      },
    },
  },
};
