import React from 'react';
import PropTypes from 'prop-types';
import { HeroFullOverlay } from '@cotyorg/ccx-organism-hero';
import { isLargeBreakpoint } from '../../../../utils';

const HeroFullOverlayContentFirst = ({
  heroStoreDataKey,
  currentBreakpoint,
}) => {
  if (isLargeBreakpoint({ currentBreakpoint })) {
    return (
      <div className="hero-full-overlay-content-first">
        <HeroFullOverlay isContentFirst heroStoreDataKey={heroStoreDataKey} />
      </div>
    );
  }

  return <HeroFullOverlay heroStoreDataKey={heroStoreDataKey} />;
};

HeroFullOverlayContentFirst.propTypes = {
  heroStoreDataKey: PropTypes.string,
  currentBreakpoint: PropTypes.string,
};

export default HeroFullOverlayContentFirst;
