import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import readJSONFromScriptTag from '@cotyorg/ccx-utils/js/helpers/read-json-from-script-tag';
import ShoppableCartButton from './components/ShoppableCartButton';
import useShoppableEvents from './components/ShoppableEvents';

const Shoppable = ({ language }) => {
  // Extract environment and Shoppable IDs from script tag
  const { ENVIRONMENT, SHOPPABLE_LIVE_ID, SHOPPABLE_STAGE_ID } =
    readJSONFromScriptTag({ id: 'clientEnv' });

  useEffect(() => {
    const { head } = document;
    const scriptId = 'shoppable-cart-script';
    const lang = language.split('-')[0]; // Extract language code (e.g., "en" from "en-US")
    window.shoppableLoaded = false; // Set a global flag for Shoppable script status

    try {
      // Dynamically create and configure the script element
      const script = document.createElement('script');
      script.defer = true;
      script.src = `https://cdn.${
        ENVIRONMENT !== 'production' ? 'staging.' : ''
      }shoppable.com/dtc6/dtc.all.min.js`;
      script.id = scriptId;

      // Set custom data attributes required by Shoppable
      script.setAttribute(
        'data-token',
        ENVIRONMENT === 'production' ? SHOPPABLE_LIVE_ID : SHOPPABLE_STAGE_ID
      );
      script.setAttribute('data-lang', lang);

      // Append the script to the document's head
      head.appendChild(script);

      // Update the global flag once the script is loaded
      window.shoppableLoaded = true;
    } catch (error) {
      console.error('Error loading Shoppable script:', error);
    }

    return () => {
      // Cleanup the script on component unmount
      try {
        const script = document.querySelector(`#${scriptId}`);
        if (script) head.removeChild(script);
      } catch (error) {
        console.error('Error removing Shoppable script:', error);
      }
    };
  }, [language]);

  useShoppableEvents();

  return <ShoppableCartButton />;
};

Shoppable.propTypes = {
  language: PropTypes.string.isRequired,
};

export default Shoppable;
