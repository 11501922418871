import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const HeroFullOverlayContentFirstContainer = ({
  childComponent: ChildComponent,
  ...props
}) => <ChildComponent {...props} />;

const mapStateToProps = ({ breakpoints = {} }) => ({
  currentBreakpoint: breakpoints.current,
});

HeroFullOverlayContentFirstContainer.propTypes = {
  childComponent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(HeroFullOverlayContentFirstContainer);
