import React from 'react';
import PropTypes from 'prop-types';
import { generateChannelAdvisorWidgetUrl, validateSku } from './helpers';
import { useAdobe } from './hooks/adobe';

const ChannelAdvisorWidget = ({ selectedSKU, url, caPid }) => {
  const validatedSku = validateSku(selectedSKU);

  useAdobe();

  return (
    <div className="channel-advisor-widget">
      <iframe
        title="channel-advisor-widget"
        scrolling="no"
        src={generateChannelAdvisorWidgetUrl({
          selectedSKU: validatedSku,
          url,
          caPid,
        })}
      />
    </div>
  );
};

ChannelAdvisorWidget.propTypes = {
  selectedSKU: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  caPid: PropTypes.string.isRequired,
};

export default ChannelAdvisorWidget;
