export const eventsNames = {
  SHADE_FINDER_POLICY_SHOWN: 'shade-finder-privacy-policy-shown',
  PAGE_VIEW: 'page_view',
  BASIC_EVENT: 'basic_event',
  SHADE_FINDER_POLICY_ACCEPTED: 'shade-finder-privacy-policy-accepted',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM_LIST: 'view_item_list',
};

export const eventsTypes = {};
