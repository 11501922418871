import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import { hideModal as hideModalAction } from '../modules';

const BasketContainer = ({ childComponent: ChildComponent, ...props }) => (
  <ChildComponent {...props} />
);

const mapStateToProps = ({ basket, content, breakpoints }) => ({
  basketUrl: R.path(['navigation', 'cartLink'], content),
  isModalShown: !!R.path(['modal', 'isShown'], basket),
  items: R.prop('items', basket),
  modalTitle: R.path(['basket', 'heading'], content),
  modalContinueMessage: R.path(
    ['basket', 'secondaryCallToAction', 'title'],
    content
  ),
  modalGoToBasketMessage: R.path(
    ['basket', 'primaryCallToAction', 'title'],
    content
  ),
  quantityLabel: R.path(['basket', 'quantityLabel'], content),
  priceLabel: R.path(['basket', 'priceLabel'], content),
  quantity: R.prop('quantity', basket),
  currentBreakpoint: R.prop('current', breakpoints),
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModalAction()),
});

BasketContainer.propTypes = {
  basket: PropTypes.objectOf(
    PropTypes.shape({
      basketUrl: PropTypes.string,
      isModalShown: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.object),
      modalTitle: PropTypes.string,
      modalContinueMessage: PropTypes.string,
      modalGoToBasketMessage: PropTypes.string,
      quantityLabel: PropTypes.string,
      priceLabel: PropTypes.string,
    })
  ),
  childComponent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketContainer);
