import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import Link from '@cotyorg/ccx-atoms/Link';

export const ShopNow = ({ extraClass, productUrl, onClick }) => (
  <Link link={productUrl} target="_blank" onClick={onClick}>
    <IconButton
      extraClass={extraClass}
      iconSrc="/ccx-files/assets/shop-now-icon.svg"
    />
  </Link>
);

ShopNow.propTypes = {
  productUrl: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  onClick: PropTypes.func,
};
