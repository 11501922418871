import React from 'react';
import HeroFullOverlayContentFirstContainer from '../../containers/HeroFullOverlayContentFirstContainer';
import HeroFullOverlayContentFirst from './HeroFullOverlayContentFirst';

const HeroFullOverlayContentFirstComponent = (props) => (
  <HeroFullOverlayContentFirstContainer
    childComponent={HeroFullOverlayContentFirst}
    {...props}
  />
);

export default HeroFullOverlayContentFirstComponent;
