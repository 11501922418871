export const modalStyles = {
  overlay: {
    zIndex: 100010,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
  },
};

export const vtoTypes = {
  SHADE_FINDER: 'shade-finder',
  MAKEUP_FINDER: 'makeup-finder',
  NAIL_VTO: 'nail-vto',
};

export const emailSharingTypes = {
  EMAIL: 'email',
  DOWNLOAD: 'download',
};

export const VTO_IFRAME_BUTTONS_SHIFT = 100;
