import { useEffect } from 'react';
import generateFacebookSharingScriptContent from '../helpers/generateFacebookSharingScriptContent';

const useScriptWithFacebookSharingParameters = ({
  async = true,
  defer = false,
  crossorigin,
  enableFacebookSharing = false,
  facebookSDKUrl = '',
  facebookSharingId = '',
  facebookSharingScriptVersion = '',
}) => {
  useEffect(() => {
    if (enableFacebookSharing) {
      const script = document.createElement('script');

      if (facebookSharingId && facebookSharingScriptVersion) {
        script.text = generateFacebookSharingScriptContent({
          facebookSharingId,
          autoLogAppEvents: true,
          xfbml: true,
          version: facebookSharingScriptVersion,
        });
      }

      if (facebookSDKUrl) {
        script.src = facebookSDKUrl;
      }

      if (crossorigin) {
        script.crossOrigin = crossorigin;
      }

      script.async = async;
      script.defer = defer;
      script.type = 'text/javascript';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [
    async,
    defer,
    crossorigin,
    enableFacebookSharing,
    facebookSDKUrl,
    facebookSharingId,
    facebookSharingScriptVersion,
  ]);
};

export default useScriptWithFacebookSharingParameters;
