import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const generateTabClassNames = ({ tab, activeVariantTab, tabExtraClass }) =>
  classNames('vto__variant-tab', tabExtraClass, {
    active: activeVariantTab === R.prop('name', tab),
  });

const generateContainerClassNames = ({ extraClass }) =>
  classNames('vto__variant-tabs', extraClass);

export const Tabs = ({
  variantTabs,
  activeVariantTab = 'single',
  onClick,
  tabExtraClass,
  extraClass,
}) => (
  <nav className={generateContainerClassNames({ extraClass })}>
    {R.map(
      (tab) => (
        <button
          key={R.prop('name', tab)}
          type="button"
          onClick={() => {
            if (onClick) {
              onClick(tab);
            }
          }}
          className={generateTabClassNames({
            tab,
            activeVariantTab,
            tabExtraClass,
          })}
        >
          {R.prop('label', tab)}
        </button>
      ),
      variantTabs
    )}
  </nav>
);

Tabs.propTypes = {
  variantTabs: PropTypes.array,
  activeVariantTab: PropTypes.number,
  onClick: PropTypes.func,
  tabExtraClass: PropTypes.string,
  extraClass: PropTypes.string,
};
