import React, { useState, useMemo, useEffect } from 'react';
import R from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HeroFullBlockQuarter } from '@cotyorg/ccx-organism-hero';
import Modal from 'react-modal';
import {
  setVtoActualProductSku as setVtoActualProductSkuAction,
  setVtoActualProductSlug as setVtoActualProductSlugAction,
  setVtoScriptLoaded as setVtoScriptLoadedAction,
  setVtoControlsHeight as setVtoControlsHeightAction,
  setActiveTab as setActiveTabAction,
  setBestMatch as setBestMatchAction,
  setMakeupFinderFilter as setMakeupFinderFilterAction,
  setCart as setCartAction,
  setShowBuyNowButton as setShowBuyNowButtonAction,
  setShowEmailSharingButton as setShowEmailSharingButtonAction,
  setShowTabs as setShowTabsAction,
} from './modules/actions';
import useScript from '../../utils/hooks/useScript';
import useScriptWithFacebookSharingParameters from '../../utils/hooks/useScriptWithFacebookSharingParameters';
import {
  getProductSwatches,
  getSelectedProduct,
  getSelectedSwatch,
  getVtoSourceUrl,
} from './helpers';
import { modalStyles, vtoTypes, emailSharingTypes } from './config';
import { Loader } from './molecules/Loader/Loader/Loader';
import {
  isXSmallBreakpoint,
  isSmallBreakpoint,
  isMediumBreakpoint,
} from '../../utils';
import { useEvents } from './hooks/events';
import { useSetActualProductData, useVtoInitialisation } from './hooks/vto';
import { dispatchEvent } from './dispatchEvent';
import VtoButton from './molecules/VtoButton/VtoButton';
import CloseButton from './atoms/CloseButton';
import {
  onCloseButtonClick,
  onHeroButtonClick,
  onOpenVto,
  onSetProductID as onSetProductIDHandler,
} from './handlers';
import { ShadeFinderVto } from './organisms/ShadeFinderVto/ShadeFinderVto';
import { MakeupFinderVto } from './organisms/MakeupFinder/MakeupFinderVto';
import { ShoppingCart } from '../ShadeFinder/components/molecules/ShoppingCart/ShoppingCart';
import { EmailSharing } from './molecules/EmailSharing/EmailSharing';
import { NailFinder } from './organisms/NailFinder/NailFinder';
import { shopNowTracking } from '../ShadeFinder/tracking';

export const generateVtoControlsClassNames = ({ isVtoLoaded }) =>
  classNames('vto__controls-container', {
    'vto__controls-container--disabled': !isVtoLoaded,
  });

const VtoModal = ({
  vto,
  currentBreakpoint,
  triggerButtonExtraClass,
  triggerButton,
  setVtoScriptLoaded,
  setVtoActualProductSku,
  setVtoActualProductSlug,
  setVtoControlsHeight,
  actualProductSku,
  setOpenButtonRef,
  openVto,
  disabledEmailSharing,
  setActiveTab,
  onVtoModalClose,
  setBestMatch,
  privacyPolicyConsent,
  setMakeupFinderFilter,
  productRecommendation,
  actualProductSlug,
  vtoType,
  isShadeFinder,
  setCart,
  vtoProducts,
  setShowBuyNowButton,
  setShowEmailSharingButton,
  setShowTabs,
}) => {
  const cart = R.prop('cart', vto);
  const activeTab = R.prop('activeTab', vto);
  const showBuyNowButton = R.prop('showBuyNowButton', vto);
  const showEmailSharingButton = R.prop('showEmailSharingButton', vto);

  const [showStartHero, setShowStartHero] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVtoOpened, setIsVtoOpened] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEmailSharingOpen, setIsEmailSharingOpen] = useState(false);
  const [emailSharingType, setEmailSharingType] = useState(
    emailSharingTypes.EMAIL
  );

  const vtoScriptLoaded = R.prop('vtoScriptLoaded', vto);
  const vtoActualProductSku = R.prop('actualProductSku', vto);
  const bestMatchSku = R.path(['bestMatch', 'bestMatchSku'], vto);
  const perfectModuleLanguage = R.prop('perfectModuleLanguage', vto);
  const enableFBSharing = !!R.prop('enableFacebookSharing', vto);
  const facebookSharingId = R.propOr('', 'facebookSharingId', vto);
  const facebookSDKUrl = R.propOr('', 'facebookSDKUrl', vto);
  const facebookSharingScriptVersion = R.propOr(
    '',
    'facebookSharingScriptVersion',
    vto
  );

  const selectedProduct = useMemo(
    () =>
      getSelectedProduct({
        products: vtoProducts,
        actualProductSlug,
      }),
    [vtoProducts, actualProductSlug]
  );

  const swatches = useMemo(
    () =>
      getProductSwatches({
        product: selectedProduct,
        bestMatchSku,
        enableClearSwatch: !isShadeFinder,
      }),
    [selectedProduct, bestMatchSku, isShadeFinder]
  );
  const selectedProductSwatch = getSelectedSwatch({
    vtoActualProductSku,
    swatches,
  });

  const onSetProductID = onSetProductIDHandler({
    setCart,
    activeTab,
    cart,
    selectedProduct,
  });

  const eventHandler = dispatchEvent({ vtoType });

  useSetActualProductData({
    vtoProducts,
    actualProductSku,
    setVtoActualProductSlug,
    setVtoActualProductSku,
  });

  useScript(getVtoSourceUrl({ vtoType, vto }));

  useVtoInitialisation({
    setVtoScriptLoaded,
    currentBreakpoint,
    setVtoControlsHeight,
    isXSmallBreakpoint,
    isSmallBreakpoint,
    isMediumBreakpoint,
    perfectModuleLanguage,
    disabledEmailSharing,
    enableFBSharing,
    privacyPolicyConsent,
    setVtoActualProductSku,
    actualProductSku,
    vtoType,
    setShowTabs,
    setActiveTab,
    vtoProducts,
    actualProductSlug,
    isVtoOpened,
  });

  useEvents({
    setVtoActualProductSlug,
    setVtoActualProductSku,
    setIsVtoOpened,
    eventHandler,
    setBestMatch,
    vtoProducts,
    setIsModalOpen,
    onVtoModalClose,
    setShowStartHero,
    vtoScriptLoaded,
    vtoType,
    productRecommendation,
    setIsCartOpen,
    setShowBuyNowButton,
    setShowEmailSharingButton,
    setIsEmailSharingOpen,
  });

  useScriptWithFacebookSharingParameters({
    async: false,
    facebookSharingId,
    facebookSharingScriptVersion,
    enableFacebookSharing: enableFBSharing,
  });

  useScriptWithFacebookSharingParameters({
    crossorigin: 'anonymous',
    facebookSDKUrl,
    enableFacebookSharing: enableFBSharing,
  });

  useEffect(() => {
    setCart({ cart: {} });
    if (isShadeFinder) {
      setActiveTab({ activeTab: 'your-match' });
    }
  }, [isShadeFinder]);

  return vtoScriptLoaded ? (
    <div>
      {!openVto && (
        <VtoButton
          extraclass={triggerButtonExtraClass}
          setRef={setOpenButtonRef}
          text={
            R.prop('text', triggerButton) ||
            R.prop('text', R.prop('button', vto))
          }
          iconSrc={
            R.prop('iconUrl', triggerButton) ||
            R.prop('iconUrl', R.prop('button', vto))
          }
          onClick={() =>
            onOpenVto({
              setIsModalOpen,
              vtoType,
            })
          }
        />
      )}

      {/* TODO: instead of change modal styles, it should not be opened in modal */}
      <Modal
        className="vto__modal"
        isOpen={isModalOpen || openVto}
        style={modalStyles}
        onAfterOpen={() =>
          onOpenVto({
            setIsModalOpen,
            vtoType,
          })
        }
      >
        <div className="grid-container full">
          <div className="grid-x">
            <div className="cell">
              <div className="vto-modal">
                {(!isVtoOpened || showStartHero) && !openVto && (
                  <CloseButton
                    imageSrc="/ccx-files/assets/cross.svg"
                    onClick={() =>
                      onCloseButtonClick({
                        setIsModalOpen,
                        setShowStartHero,
                        onVtoModalClose,
                        setVtoActualProductSlug,
                        setVtoActualProductSku,
                        eventHandler,
                        setShowBuyNowButton,
                        setShowEmailSharingButton,
                      })
                    }
                  />
                )}

                {!isVtoOpened && !showStartHero && <Loader />}

                {showStartHero && (
                  <div className="vto-modal__hero">
                    <HeroFullBlockQuarter
                      heroStoreDataKey="startHero"
                      ctaType="button"
                      layout="vertical"
                      isUnderlined={false}
                      onClick={() =>
                        onHeroButtonClick({
                          setShowStartHero,
                          eventHandler,
                        })
                      }
                    />
                  </div>
                )}

                <div className="cell">
                  <div id="YMK-module" />

                  {vtoType === vtoTypes.SHADE_FINDER && (
                    <ShadeFinderVto
                      defaultProductSku={actualProductSku}
                      showBuyNowButton={showBuyNowButton}
                      showEmailSharingButton={showEmailSharingButton}
                      selectedProduct={selectedProduct}
                      vto={vto}
                      eventHandler={eventHandler}
                      setActiveTab={setActiveTab}
                      setVtoActualProductSlug={setVtoActualProductSlug}
                      vtoActualProductSku={vtoActualProductSku}
                      onSetProductID={onSetProductID}
                      setVtoActualProductSku={setVtoActualProductSku}
                      selectedProductSwatch={selectedProductSwatch}
                      swatches={swatches}
                      isVtoLoaded={isVtoOpened}
                      vtoType={vtoType}
                      setIsCartOpen={setIsCartOpen}
                      setIsEmailSharingOpen={setIsEmailSharingOpen}
                      setEmailSharingType={setEmailSharingType}
                      useShoppingCart={R.path(
                        ['shadeFinder', 'cart', 'useShoppingCart'],
                        vto
                      )}
                    />
                  )}

                  {vtoType === vtoTypes.MAKEUP_FINDER && (
                    <MakeupFinderVto
                      defaultProductSku={actualProductSku}
                      showBuyNowButton={showBuyNowButton}
                      showEmailSharingButton={showEmailSharingButton}
                      selectedProduct={selectedProduct}
                      vto={vto}
                      eventHandler={eventHandler}
                      setActiveTab={setActiveTab}
                      setVtoActualProductSlug={setVtoActualProductSlug}
                      vtoActualProductSku={vtoActualProductSku}
                      onSetProductID={onSetProductID}
                      setVtoActualProductSku={setVtoActualProductSku}
                      selectedProductSwatch={selectedProductSwatch}
                      swatches={swatches}
                      isVtoLoaded={isVtoOpened}
                      vtoType={vtoType}
                      setMakeupFinderFilter={setMakeupFinderFilter}
                      setIsCartOpen={setIsCartOpen}
                      setIsEmailSharingOpen={setIsEmailSharingOpen}
                      setEmailSharingType={setEmailSharingType}
                      useShoppingCart={R.path(
                        ['makeupFinder', 'cart', 'useShoppingCart'],
                        vto
                      )}
                      setShowTabs={setShowTabs}
                    />
                  )}

                  {vtoType === vtoTypes.NAIL_VTO && (
                    <NailFinder
                      defaultProductSku={actualProductSku}
                      showBuyNowButton={showBuyNowButton}
                      showEmailSharingButton={showEmailSharingButton}
                      selectedProduct={selectedProduct}
                      vto={vto}
                      eventHandler={eventHandler}
                      setActiveTab={setActiveTab}
                      setVtoActualProductSlug={setVtoActualProductSlug}
                      vtoActualProductSku={vtoActualProductSku}
                      onSetProductID={onSetProductID}
                      setVtoActualProductSku={setVtoActualProductSku}
                      selectedProductSwatch={selectedProductSwatch}
                      swatches={swatches}
                      isVtoLoaded={isVtoOpened}
                      vtoType={vtoType}
                      setMakeupFinderFilter={setMakeupFinderFilter}
                      setIsCartOpen={setIsCartOpen}
                      setIsEmailSharingOpen={setIsEmailSharingOpen}
                      setEmailSharingType={setEmailSharingType}
                      vtoProducts={vtoProducts}
                      useShoppingCart={R.path(
                        ['nailFinder', 'cart', 'useShoppingCart'],
                        vto
                      )}
                    />
                  )}
                </div>

                {R.path(['downloadPicture', 'downloadText'], vto) &&
                  isEmailSharingOpen && (
                    <EmailSharing
                      imageUrl={isEmailSharingOpen}
                      selectedProduct={selectedProduct}
                      selectedProductSwatch={selectedProductSwatch}
                      vtoActualProductSku={vtoActualProductSku}
                      downloadText={R.path(
                        ['downloadPicture', 'downloadText'],
                        vto
                      )}
                      retakeText={R.path(
                        ['downloadPicture', 'retakeText'],
                        vto
                      )}
                      buttonText={R.path(['emailSharing', 'buttonText'], vto)}
                      formHeader={R.path(['emailSharing', 'formHeader'], vto)}
                      formInfo={R.path(['emailSharing', 'formInfo'], vto)}
                      formButtonText={R.path(
                        ['emailSharing', 'formButtonText'],
                        vto
                      )}
                      inputName={R.path(['emailSharing', 'inputName'], vto)}
                      inputEmail={R.path(['emailSharing', 'inputEmail'], vto)}
                      klaviyoSrc={R.path(['emailSharing', 'klaviyoSrc'], vto)}
                      klaviyoPassword={R.path(
                        ['emailSharing', 'klaviyoPassword'],
                        vto
                      )}
                      formSuccessText={R.path(
                        ['emailSharing', 'formSuccessText'],
                        vto
                      )}
                      formErrorText={R.path(
                        ['emailSharing', 'formErrorText'],
                        vto
                      )}
                      formBaseUrl={R.path(['emailSharing', 'formBaseUrl'], vto)}
                      emailSharingType={emailSharingType}
                      eventHandler={eventHandler}
                      onCloseClick={() => setIsEmailSharingOpen(false)}
                      onRetakeClick={() => {
                        if (window.YMK) {
                          try {
                            setIsEmailSharingOpen(false);
                            // YMK.backToLivecam();
                            YMK.retake();
                          } catch (error) {
                            console.error(error);
                          }
                        }
                      }}
                    />
                  )}

                {isCartOpen &&
                  ((R.path(['shadeFinder', 'cart', 'useShoppingCart'], vto) &&
                    vtoType === vtoTypes.SHADE_FINDER) ||
                    (R.path(['makeupFinder', 'cart', 'useShoppingCart'], vto) &&
                      vtoType === vtoTypes.MAKEUP_FINDER) ||
                    (R.path(['nailFinder', 'cart', 'useShoppingCart'], vto) &&
                      vtoType === vtoTypes.NAIL_VTO)) && (
                    <ShoppingCart
                      cart={cart}
                      onCloseClick={() => setIsCartOpen(false)}
                      heading={R.path(['makeupFinder', 'cart', 'heading'], vto)}
                      buyNowButtonText={R.path(['buyNowButton', 'text'], vto)}
                      channelAdvisorSourceUrl={R.prop(
                        'channelAdvisorSourceUrl',
                        vto
                      )}
                      channelAdvisorPid={R.prop('channelAdvisorPid', vto)}
                      onBuyButtonClick={({ productCard }) => {
                        if (R.prop('sku', productCard)) {
                          shopNowTracking({
                            shadeName: R.prop('itemName', productCard),
                            product: productCard,
                            vtoType,
                          });
                        }
                      }}
                      recommendedProductsHeading={
                        vtoType === vtoTypes.SHADE_FINDER
                          ? R.path(
                              [
                                'shadeFinder',
                                'cart',
                                'recommendedProductsHeading',
                              ],
                              vto
                            )
                          : R.path(
                              [
                                'makeupFinder',
                                'cart',
                                'recommendedProductsHeading',
                              ],
                              vto
                            )
                      }
                      vtoType={vtoType}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};

VtoModal.propTypes = {
  disabledEmailSharing: PropTypes.bool,
  setVtoActualProductSku: PropTypes.func.isRequired,
  setVtoActualProductSlug: PropTypes.func.isRequired,
  setVtoScriptLoaded: PropTypes.func.isRequired,
  setVtoControlsHeight: PropTypes.func.isRequired,
  vto: PropTypes.shape({
    button: PropTypes.shape({
      text: PropTypes.string,
      iconUrl: PropTypes.string,
    }),
    buyNowButton: PropTypes.shape({
      text: PropTypes.string,
    }),
    downloadPicture: PropTypes.shape({
      downloadText: PropTypes.string,
      retakeText: PropTypes.string,
    }),
    vtoSourceUrl: PropTypes.string.isRequired,
    actualProductSku: PropTypes.string,
    actualProductSlug: PropTypes.string,
    vtoScriptLoaded: PropTypes.bool,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(PropTypes.object),
      })
    ),
  }),
  currentBreakpoint: PropTypes.string.isRequired,
  triggerButtonExtraClass: PropTypes.string,
  triggerButton: PropTypes.shape({
    text: PropTypes.string,
    iconUrl: PropTypes.string,
  }),
  actualProductSku: PropTypes.string,
  setOpenButtonRef: PropTypes.func,
  openVto: PropTypes.bool,
  setActiveTab: PropTypes.func,
  onVtoModalClose: PropTypes.func,
  setBestMatch: PropTypes.func.isRequired,
  privacyPolicyConsent: PropTypes.bool,
  setMakeupFinderFilter: PropTypes.func.isRequired,
  productRecommendation: PropTypes.string,
  actualProductSlug: PropTypes.string.isRequired,
  vtoType: PropTypes.string.isRequired,
  isShadeFinder: PropTypes.string.isRequired,
  setCart: PropTypes.func.isRequired,
  vtoProducts: PropTypes.array.isRequired,
  setShowBuyNowButton: PropTypes.func.isRequired,
  setShowEmailSharingButton: PropTypes.func.isRequired,
  setShowTabs: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setVtoActualProductSku: ({ actualProductSku }) =>
    dispatch(setVtoActualProductSkuAction({ actualProductSku })),
  setVtoActualProductSlug: ({ actualProductSlug }) =>
    dispatch(setVtoActualProductSlugAction({ actualProductSlug })),
  setVtoScriptLoaded: ({ vtoScriptLoaded }) =>
    dispatch(setVtoScriptLoadedAction({ vtoScriptLoaded })),
  setVtoControlsHeight: ({ vtoControlsHeight }) =>
    dispatch(setVtoControlsHeightAction({ vtoControlsHeight })),
  setActiveTab: ({ activeTab }) => dispatch(setActiveTabAction({ activeTab })),
  setBestMatch: ({ bestMatch }) => dispatch(setBestMatchAction({ bestMatch })),
  setCart: ({ cart }) => dispatch(setCartAction({ cart })),
  setShowBuyNowButton: ({ showBuyNowButton }) =>
    dispatch(setShowBuyNowButtonAction({ showBuyNowButton })),
  setShowEmailSharingButton: ({ showEmailSharingButton }) =>
    dispatch(setShowEmailSharingButtonAction({ showEmailSharingButton })),
  setMakeupFinderFilter: ({ filter }) =>
    dispatch(setMakeupFinderFilterAction({ filter })),
  setShowTabs: ({ showTabs }) => dispatch(setShowTabsAction({ showTabs })),
});

export default connect(
  ({ content, breakpoints }) => ({
    vto: R.prop('vto', content),
    currentBreakpoint: R.prop('current', breakpoints),
  }),
  mapDispatchToProps
)(VtoModal);
