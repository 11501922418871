import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  pageViewTracking,
  quizQuestionTracking,
} from '../../ShadeFinder/tracking';
import { pagesNames } from '../../ShadeFinder/helpers/constants';

const generateAnswerCssClass = (answer) =>
  classNames('shade-finder-quiz__answer', {
    'shade-finder-quiz__answer--skip': R.prop('skip', answer),
  });

const Answer = ({
  answer,
  quizStep,
  setQuizStep,
  quizAnswers,
  setQuizAnswers,
  vtoType,
}) => (
  <button
    type="button"
    className={generateAnswerCssClass(answer)}
    onClick={() => {
      setQuizAnswers([...quizAnswers, R.prop('uid', answer)]);
      setQuizStep(quizStep + 1);

      const names = [
        pagesNames.PREFERRED_FINISH,
        pagesNames.FOUNDATION_COVERAGE,
        pagesNames.ADDITIONAL_BENEFIT,
      ];

      pageViewTracking({ pageName: names[quizStep], vtoType });
      quizQuestionTracking({
        questionIndex: quizStep,
        answer: R.prop('value', answer),
        vtoType,
      });
    }}
  >
    <span>{R.prop('value', answer)}</span>
  </button>
);

Answer.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    skip: PropTypes.bool.isRequired,
  }).isRequired,
  quizStep: PropTypes.number.isRequired,
  setQuizStep: PropTypes.func.isRequired,
  quizAnswers: PropTypes.array.isRequired,
  setQuizAnswers: PropTypes.func.isRequired,
  vtoType: PropTypes.string,
};

export default Answer;
