import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import Button from '@cotyorg/ccx-atoms/Button';
import { emailSharingTypes } from '../../config';
import Canvas from './Canvas';
import EmailSharingForm from './EmailSharingForm';

export const EmailSharing = ({
  imageUrl,
  selectedProduct,
  selectedProductSwatch,
  vtoActualProductSku,
  downloadText,
  retakeText,
  buttonText,
  formHeader,
  formInfo,
  formButtonText,
  inputName,
  inputEmail,
  klaviyoSrc,
  klaviyoPassword,
  formSuccessText,
  formErrorText,
  formBaseUrl,
  emailSharingType,
  eventHandler,
  onCloseClick,
  onRetakeClick,
}) => {
  const [isEmailForm, setIsEmailForm] = useState(false);

  return (
    <div className="emailSharingPopup" id="emailSharingPopup">
      <div className="emailSharingPopup__content">
        <IconButton
          extraClass="emailSharingPopup__close-button"
          iconSrc="/ccx-files/assets/close.png"
          onClick={onCloseClick}
        />

        <Canvas
          imageUrl={imageUrl}
          selectedProduct={selectedProduct}
          selectedProductSwatch={selectedProductSwatch}
        />

        <div className="emailSharingPopup__buttons">
          {emailSharingType === emailSharingTypes.EMAIL && (
            <Button
              extraClass="button--confirm"
              onClick={() => {
                setIsEmailForm(true);
                eventHandler({
                  eventName: 'vto-share-email-confirm',
                });
              }}
            >
              {buttonText}
            </Button>
          )}
          {emailSharingType === emailSharingTypes.DOWNLOAD && (
            <Button
              extraClass="button--download"
              onClick={() => {
                const canvas = document.getElementById('previewImage');
                const image = canvas
                  .toDataURL('image/png', 1.0)
                  .replace('image/png', 'image/octet-stream');
                const link = document.createElement('a');
                link.download = `${selectedProduct.title.replaceAll(
                  ' ',
                  '-'
                )}.png`;
                link.href = image;
                link.click();
                eventHandler({
                  eventName: 'vto-download-picture-download',
                });
              }}
            >
              {downloadText}
            </Button>
          )}
          <Button extraClass="button--retake" onClick={onRetakeClick}>
            {retakeText}
          </Button>
        </div>

        {isEmailForm && (
          <EmailSharingForm
            formHeader={formHeader}
            formInfo={formInfo}
            formButtonText={formButtonText}
            inputName={inputName}
            inputEmail={inputEmail}
            klaviyoSrc={klaviyoSrc}
            klaviyoPassword={klaviyoPassword}
            formSuccessText={formSuccessText}
            formErrorText={formErrorText}
            formBaseUrl={formBaseUrl}
            vtoActualProductSku={vtoActualProductSku}
            imageUrl={imageUrl}
            eventHandler={eventHandler}
          />
        )}
      </div>
    </div>
  );
};

EmailSharing.propTypes = {
  imageUrl: PropTypes.string,
  selectedProduct: PropTypes.object,
  selectedProductSwatch: PropTypes.object,
  downloadText: PropTypes.string,
  retakeText: PropTypes.string,
  buttonText: PropTypes.string,
  formHeader: PropTypes.string,
  formInfo: PropTypes.string,
  formButtonText: PropTypes.string,
  inputName: PropTypes.string,
  inputEmail: PropTypes.string,
  emailSharingType: PropTypes.string,
  onCloseClick: PropTypes.func,
  onRetakeClick: PropTypes.func,
  vtoActualProductSku: PropTypes.string,
  klaviyoSrc: PropTypes.string,
  klaviyoPassword: PropTypes.string,
  formSuccessText: PropTypes.string,
  formErrorText: PropTypes.string,
  formBaseUrl: PropTypes.string,
  eventHandler: PropTypes.func,
};
