import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const generateSectionHeaderCssClass = ({ textAlign, extraClass }) =>
  classNames('section__header', {
    'section__header--left': textAlign === 'left',
    [extraClass]: extraClass,
  });

const SectionHeader = ({ children, textAlign, extraClass }) => (
  <header className={generateSectionHeaderCssClass({ textAlign, extraClass })}>
    {children}
  </header>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.oneOf(['left']),
  extraClass: PropTypes.string,
};

export default SectionHeader;
