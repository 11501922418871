import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@cotyorg/ccx-atoms/Input';
import Button from '@cotyorg/ccx-atoms/Button';
import Paragraph from '@cotyorg/ccx-atoms/Paragraph';
import { base64ToBlob } from '../../helpers';

const EmailSharingForm = ({
  formHeader,
  formInfo,
  formButtonText,
  inputName,
  inputEmail,
  vtoActualProductSku,
  imageUrl,
  klaviyoSrc,
  klaviyoPassword,
  formSuccessText,
  formErrorText,
  formBaseUrl,
  eventHandler,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [formSentText, setFormSentText] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', klaviyoPassword);

    const data = {
      email: e.target.email.value,
      name: e.target.name.value,
      site: formBaseUrl,
      content_type: 'image/png',
      main: vtoActualProductSku,
    };

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    };

    setIsLoader(true);
    eventHandler({
      eventName: 'vto-share-email-send',
    });

    await fetch(klaviyoSrc, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        const formDataImage = new FormData();
        Object.entries(res.signed_upload.fields).forEach((entry) => {
          const [key, value] = entry;
          formDataImage.append(key, value);
        });

        formDataImage.append('File', base64ToBlob(imageUrl));

        const requestOptionsImage = {
          method: 'POST',
          body: formDataImage,
        };

        fetch(res.signed_upload.url, requestOptionsImage)
          .then(() => {
            setIsLoader(false);
            setFormSentText(formSuccessText);
          })
          .catch(() => {
            setIsLoader(false);
            setFormSentText(formErrorText);
          });
      })
      .catch(() => {
        setIsLoader(false);
        setFormSentText(formErrorText);
      });
  };

  return (
    <>
      <div className="emailSharingForm">
        <div className="emailSharingForm__content">
          {!isLoader && !formSentText && (
            <form onSubmit={(e) => onSubmit(e)}>
              <Input
                testAttribute="newsletter-input"
                placeholder={inputName}
                type="text"
                name="name"
                onChange={handleChange}
                required
              />

              <Input
                testAttribute="newsletter-input"
                placeholder={inputEmail}
                type="email"
                name="email"
                onChange={handleChange}
                required
              />

              <Paragraph extraClass="info">{formInfo}</Paragraph>

              <Button
                extraClass="newsletter__button button--primary"
                theme="primary"
              >
                {formButtonText}
              </Button>
            </form>
          )}

          {isLoader && <div className="emailSharingForm-info">Loading</div>}

          {formSentText && (
            <Paragraph extraClass="emailSharingForm-info">
              {formSentText}
            </Paragraph>
          )}
        </div>
      </div>
    </>
  );
};

EmailSharingForm.propTypes = {
  formHeader: PropTypes.string,
  formInfo: PropTypes.string,
  formButtonText: PropTypes.string,
  inputName: PropTypes.string,
  inputEmail: PropTypes.string,
  vtoActualProductSku: PropTypes.string,
  imageUrl: PropTypes.string,
  klaviyoSrc: PropTypes.string,
  klaviyoPassword: PropTypes.string,
  formSuccessText: PropTypes.string,
  formErrorText: PropTypes.string,
  formBaseUrl: PropTypes.string,
  eventHandler: PropTypes.func,
};

export default EmailSharingForm;
