import { useEffect } from 'react';
import dispatch from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/dispatch';

// Function to collect products from merchants
const getProductsFromMerchants = (cartContent) => {
  let items = [];

  // Check if cartContent.merchants exists and is an array
  if (cartContent.merchants && cartContent.merchants.constructor === Array) {
    for (let i = 0; i < cartContent.merchants.length; i += 1) {
      const merchant = cartContent.merchants[i];
      if (merchant.products && merchant.products.length > 0) {
        items = items.concat(merchant.products);
      }
    }
  }

  return items;
};

// Helper function to extract cart details, including products from merchants
const getCartDetails = (cartContent) => ({
  eventValue: cartContent && cartContent.total ? cartContent.total : 0,
  cartQuantity:
    cartContent && cartContent.totalProductQuantity
      ? cartContent.totalProductQuantity
      : 0,
  items: getProductsFromMerchants(cartContent), // Assuming getProductsFromMerchants is well defined
});

// Event handlers definition
const eventHandlers = {
  onAddToCart: ({ cartContent, eventSpecificData }) => {
    const detail = {
      eventInfo: {
        eventAction: 'add to cart',
        eventName: 'add-to-cart',
        eventType: 'userEvent',
        currency: 'gpb',
        eventValue:
          eventSpecificData &&
          eventSpecificData[0] &&
          eventSpecificData[0].quantity &&
          eventSpecificData[0].salePrice
            ? eventSpecificData[0].quantity * eventSpecificData[0].salePrice
            : 0,
      },
      items: eventSpecificData || [],
    };

    console.log('event: add-to-cart', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'add-to-cart',
      detail,
    });
  },

  onCartOpened: ({ cartContent }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'view shopping bag',
        eventName: 'view-cart',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
    };

    console.log('event: view-cart', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'view-cart',
      detail,
    });
  },

  onMerchantChanged: ({ cartContent }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'select new retailer',
        eventName: 'retailer-update',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
    };

    console.log('event: retailer-update', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'retailer-update',
      detail,
    });
  },

  onCheckoutStarted: ({ cartContent }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'begin checkout',
        eventName: 'begin-checkout',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
    };

    console.log('event: begin-checkout', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'begin-checkout',
      detail,
    });
  },

  onPaymentStarted: ({ cartContent }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'place an order',
        eventName: 'place-order',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
    };

    console.log('event: place-order', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'place-order',
      detail,
    });
  },

  onExpressCheckoutStarted: ({ cartContent }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'place an order',
        eventName: 'place-order',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
    };

    console.log('event: place-order', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'place-order',
      detail,
    });
  },

  onOrderPlaced: ({ cartContent, eventSpecificData }) => {
    const { items, cartQuantity, eventValue } = getCartDetails(cartContent); // Using the helper function

    const detail = {
      eventInfo: {
        eventAction: 'purchase',
        eventName: 'order-purchase',
        eventType: 'userEvent',
        currency: 'gbp',
        eventValue,
      },
      items,
      cartQuantity,
      transactionid: eventSpecificData,
    };

    console.log('event: order-purchase', detail); // temporarily for testing purposes

    dispatch({
      eventName: 'order-purchase',
      detail,
    });
  },
};

export default () => {
  useEffect(() => {
    // Get the list of handled event names
    const handledEvents = Object.keys(eventHandlers);

    // Store references to event listeners for cleanup
    const listeners = {};

    handledEvents.forEach((eventHandler) => {
      if (eventHandlers[eventHandler]) {
        // Define the event listener function
        const handler = (event) => {
          const { detail } = event;
          const cartContent = detail.cartContent || [];
          const eventSpecificData = detail.eventSpecificData || {};
          eventHandlers[eventHandler]({
            cartContent,
            eventSpecificData,
          });
        };

        // Add the event listener
        window.addEventListener(eventHandler, handler);

        // Store the listener reference for later removal
        listeners[eventHandler] = handler;
      }
    });

    return () => {
      // Cleanup: Remove all event listeners
      handledEvents.forEach((eventHandler) => {
        if (listeners[eventHandler]) {
          window.removeEventListener(eventHandler, listeners[eventHandler]);
        }
      });
    };
  }, []);
};
