const generateFacebookSharingScriptContent = ({
  facebookSharingId,
  autoLogAppEvents = true,
  xfbml = true,
  version,
}) => `window.fbAsyncInit = function() {
    FB.init({
      appId            : '${facebookSharingId}',
      autoLogAppEvents : ${autoLogAppEvents},
      xfbml            : ${xfbml},
      version          : '${version}'
    });
  }`;

export default generateFacebookSharingScriptContent;
