import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@cotyorg/ccx-molecules/IconButton';
import { emailSharingTypes } from '../config';

export const EmailSharingButton = ({
  extraClass,
  setEmailSharingType,
  eventHandler,
}) => {
  const [isIsShareButtonOpened, setIsShareButtonOpened] = useState(false);

  return (
    <div>
      {!isIsShareButtonOpened && (
        <IconButton
          extraClass={extraClass}
          onClick={() => {
            setIsShareButtonOpened(true);
          }}
          iconSrc="/ccx-files/assets/share.svg"
        />
      )}
      {isIsShareButtonOpened && (
        <div className="vto__modal-download_picture-button-open">
          <IconButton
            onClick={() => {
              if (window.YMK) {
                try {
                  YMK.retake();
                } catch (error) {
                  console.error(error);
                }
              }
              setEmailSharingType(emailSharingTypes.EMAIL);
              setIsShareButtonOpened(false);
              eventHandler({
                eventName: 'vto-share-email',
              });
            }}
            iconSrc="/ccx-files/assets/email.svg"
          />
          <IconButton
            onClick={() => {
              if (window.YMK) {
                try {
                  YMK.retake();
                } catch (error) {
                  console.error(error);
                }
                setEmailSharingType(emailSharingTypes.DOWNLOAD);
                setIsShareButtonOpened(false);
                eventHandler({
                  eventName: 'vto-download-picture',
                });
              }
            }}
            iconSrc="/ccx-files/assets/download.svg"
          />
          <IconButton
            onClick={() => {
              setIsShareButtonOpened(false);
            }}
            extraClass="vto__modal-download_picture-button-close"
            iconSrc="/ccx-files/assets/close-button.svg"
          />
        </div>
      )}
    </div>
  );
};

EmailSharingButton.propTypes = {
  extraClass: PropTypes.string,
  setEmailSharingType: PropTypes.func,
  eventHandler: PropTypes.func,
};
